import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface SessionState {
    loggedIn: boolean;
    token: string;
    userId: string;
    email: string;
    phone: string;
}
const initialState: SessionState = {
    loggedIn: false,
    token: '',
    userId: '',
    email: '',
    phone: ''
};

export const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.loggedIn = action.payload;
        },
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
        },
        setUserId: (state, action: PayloadAction<string>) => {
            state.userId = action.payload;
        },
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        setPhone: (state, action: PayloadAction<string>) => {
            state.phone = action.payload;
        }
    }
});

export const { setLoggedIn, setToken, setUserId, setEmail, setPhone } =
    sessionSlice.actions;

export default sessionSlice.reducer;
