import {
    Box,
    Flex,
    Text,
    IconButton,
    Stack,
    Collapse,
    Link,
    Popover,
    PopoverTrigger,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
    Container,
    Heading,
    Divider,
    PopoverContent,
    textDecoration,
    Icon,
    Spacer
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import React from 'react';

export default function Navbar() {
    const isMobile = useBreakpointValue({ base: false, lg: true });

    const { isOpen, onToggle } = useDisclosure();
    const [selectedNavItem, setSelectedNavItem] = useState<string | null>(null);
    const location = useLocation();

    useEffect(() => {
        const currentPath = location.pathname;
        const matchedNavItem = NAV_ITEMS.find(
            (navItem) => navItem.href === currentPath
        );

        setSelectedNavItem(matchedNavItem ? matchedNavItem.label : null);
    }, [location]);

    return (
        <Flex background="white" pt={2} pb={2} direction={'column'} w="full">
            <Flex
                // bg={useColorModeValue('white', 'gray.800')}
                // color={useColorModeValue('gray.600', 'white')}

                py={{ base: 2 }}
                px={{ base: 4 }}
                align={'center'}
            >
                <Container
                    maxW={useBreakpointValue({ base: '100%', xl: '68%' })}
                >
                    <Flex
                        flex={1}
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Link
                            textAlign={useBreakpointValue({
                                base: 'center',
                                md: 'left'
                            })}
                            fontSize="xl"
                            fontWeight={500}
                            color="green.400" // Set color to green
                            href="/"
                            _hover={{
                                textDecoration: 'none'
                            }}
                        >
                            {import.meta.env.VITE_APP_NAME}
                        </Link>

                        <Flex display={isMobile ? 'flex' : 'none'}>
                            <DesktopNav selectedNavItem={selectedNavItem} />
                        </Flex>
                    </Flex>
                </Container>

                <Flex
                    flex={{ base: 1, md: 'auto' }}
                    ml={{ base: -2 }}
                    display={isMobile ? 'none' : 'flex'}
                    justifyContent="flex-end"
                >
                    <IconButton
                        onClick={onToggle}
                        icon={
                            isOpen ? (
                                <CloseIcon w={3} h={3} />
                            ) : (
                                <HamburgerIcon w={5} h={5} />
                            )
                        }
                        variant={'ghost'}
                        aria-label={'Toggle Navigation'}
                    />
                </Flex>
            </Flex>

            <Spacer />

            <Collapse in={isOpen} animateOpacity>
                <MobileNav selectedNavItem={selectedNavItem} />
            </Collapse>
        </Flex>
    );
}

const DesktopNav = ({
    selectedNavItem
}: {
    selectedNavItem: string | null;
}) => {
    return (
        <Stack direction={'row'} spacing={4}>
            {NAV_ITEMS.map((navItem) => (
                <Box key={navItem.label} height="5">
                    <Popover trigger={'hover'} placement={'bottom-start'}>
                        <PopoverTrigger>
                            {navItem.label !== 'Cart' ? (
                                <Link
                                    p={2}
                                    href={navItem.href ?? '#'}
                                    fontSize={'sm'}
                                    fontWeight={500}
                                    _hover={{
                                        textDecoration: 'none',
                                        color: 'blue.400'
                                    }}
                                    color={
                                        selectedNavItem === navItem.label
                                            ? 'blue.400'
                                            : 'gray.600'
                                    }
                                >
                                    {navItem.label.toUpperCase()}
                                    <Divider
                                        borderColor={
                                            selectedNavItem === navItem.label
                                                ? 'blue.400'
                                                : 'gray.600'
                                        }
                                        style={{
                                            width: '94%',
                                            marginLeft: '6px',
                                            borderTopWidth: '1px'
                                        }}
                                        hidden={
                                            selectedNavItem !== navItem.label
                                        }
                                    />
                                </Link>
                            ) : (
                                <Link href={navItem.href ?? '#'}>
                                    <Icon
                                        mt="1"
                                        as={FaShoppingCart}
                                        boxSize={4}
                                        color="green.400"
                                        _hover={{ color: 'black' }}
                                    />
                                </Link>
                            )}
                        </PopoverTrigger>
                        {/* <PopoverContent
							borderColor="transparent"
							borderTopWidth="0px"
						>
							<Divider
								borderColor={'blue.400'}
								style={{
									width: '94%',
									marginLeft: '6px',
									borderTopWidth: '1px'
								}}
							/>
						</PopoverContent> */}
                    </Popover>
                </Box>
            ))}
        </Stack>
    );
};

const MobileNav = ({ selectedNavItem }: { selectedNavItem: string | null }) => {
    return (
        <Stack bg={useColorModeValue('white', 'gray.800')} p={4}>
            {NAV_ITEMS.map((navItem) => (
                <MobileNavItem
                    key={navItem.label}
                    {...navItem}
                    selectedNavItem={selectedNavItem}
                />
            ))}
        </Stack>
    );
};

const MobileNavItem = ({
    label,
    href,
    selectedNavItem
}: NavItem & { selectedNavItem: string | null }) => {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Stack
            spacing={0}
            onClick={onToggle}
            align="center"
            backgroundColor={'white'}
        >
            <Flex
                py={2}
                as={Link}
                href={href ?? '#'}
                justify="center"
                align="center"
                backgroundColor={selectedNavItem === label ? 'blue.400' : ''}
                _hover={{
                    backgroundColor: 'blue.400',
                    textDecoration: 'none',
                    color: 'white'
                }}
                width="100%"
                color={selectedNavItem === label ? 'white' : 'gray.400'}
            >
                <Text fontWeight={600}>{label}</Text>
            </Flex>
            <Divider backgroundColor={'gray.200'} />
        </Stack>
    );
};

interface NavItem {
    label: string;
    href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
    // {
    //     label: 'Home',
    //     href: 'http://caraplug.local'
    // },
    // {
    //     label: 'Product',
    //     href: 'http://caraplug.local/product-details'
    // },
    // {
    //     label: 'Store',
    //     href: 'http://caraplug.local/product/irrigation-guardian'
    // },
    // {
    //     label: 'News',
    //     href: 'http://caraplug.local/news'
    // },
    // {
    //     label: 'Support',
    //     href: 'http://caraplug.local/support'
    // },
    // {
    //     label: 'My Devices',
    //     href: '/devices'
    // },
    // {
    //     label: 'Cart',
    //     href: 'http://caraplug.local/cart'
    // }
];
