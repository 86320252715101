import { initializeApp } from '@firebase/app';
import { getAuth } from '@firebase/auth';
import { initializeAppCheck, ReCaptchaV3Provider } from '@firebase/app-check';
import { getAnalytics } from '@firebase/analytics';
import { getPerformance } from '@firebase/performance';

// the firebase config info you get from your Firebase Console
const firebaseConfig = JSON.parse(import.meta.env.VITE_FB_WB);

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
auth.useDeviceLanguage();

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
export const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(import.meta.env.VITE_RECAPTCHA_SITE_KEY),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
});

// Initialize Firebase Analytics
export const analytics = getAnalytics(app);
// Initialize Firebase Performance Monitoring
export const perf = getPerformance(app);
