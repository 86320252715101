import {
    Badge,
    Box,
    Button,
    Center,
    Container,
    Flex,
    Heading,
    Text,
    VStack
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { SubmitBtn } from '../components/login/Components';
import React from 'react';

export default function Verification() {
    const [code, setCode] = useState('');

    useEffect(() => {
        // extract code from query param
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
            setCode(code);
        }
    }, []);

    return (
        <Center height="60vh">
            <Container padding={8}>
                <VStack spacing={10}>
                    <Heading fontWeight={300} size="lg" textAlign="center">
                        Your verification code is
                    </Heading>
                    <Badge
                        fontSize="25px"
                        fontWeight="bold"
                        colorScheme="blue"
                        p={3}
                        sx={{ letterSpacing: '0.3em' }} // Customize the letter spacing value as needed
                    >
                        {code}
                    </Badge>
                </VStack>
            </Container>
        </Center>
    );
}
