import {
    Alert,
    AlertIcon,
    Button,
    Divider,
    FormControl,
    FormErrorMessage,
    Heading,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Tr,
    VStack,
    Text,
    HStack
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
// redux
import { useSelector } from 'react-redux';
import { SubmitBtn } from './Components';
import { signOut } from '@root/src/shared/auth/firebaseUtils';
import { RootState } from '@root/src/redux/store';
import React from 'react';
import { auth } from '../../shared/auth/firebaseConfig';
import { SubmitType } from './Components';

// enum or source
enum SmartHomeAuthSource {
    GoogleHome = 'Google',
    Alexa = 'Alexa'
}

interface LoginMethodProps {
    userShowing: boolean;
    authCanceled: () => void;
    authSuccess: () => Promise<void>;
}
export default function User({
    userShowing,
    authCanceled,
    authSuccess
}: Readonly<LoginMethodProps>) {
    // #region business-logic
    const session = useSelector((state: RootState) => state.session);

    const [source, setSource] = useState<SmartHomeAuthSource | null>(null);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState('');
    const [showSubmitError, setShowSubmitError] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!userShowing) return;

        resetForm();

        init();
    }, [userShowing]);

    useEffect(() => {
        const { email, phone } = session;
        setEmail(email);
        setPhone(phone);
    }, [session]);

    function resetForm() {
        // reset form
        setSubmitting(false);
        setSubmitError('');
        setShowSubmitError(false);
    }

    async function init() {
        // need to wait for user to be loaded before allowing user to submit, o/w they see "User is null" error when submitting
        await auth.authStateReady();
        while (auth.currentUser == null) {
            await new Promise((resolve) => setTimeout(resolve, 100));
        }

        const queryParams = new URLSearchParams(window.location.search);
        const redirect_uri = queryParams.get('redirect_uri');
        if (redirect_uri?.includes('google')) {
            setSource(SmartHomeAuthSource.GoogleHome);
        } else if (redirect_uri?.includes('amazon')) {
            setSource(SmartHomeAuthSource.Alexa);
        }

        setLoading(false);
    }

    const handleSignOut = async () => {
        signOut();
    };

    const handleCancelBtn = async (e: any) => {
        e.preventDefault();
        authCanceled();
    };

    const handleAllowBtn = async (e: any) => {
        e.preventDefault();

        setSubmitting(true);
        setShowSubmitError(false);

        try {
            await authSuccess();

            // if successful, only set submitting to false after waiting a few seconds for redirect to happen
            setTimeout(() => {
                setSubmitting(false);
            }, 3000);
        } catch (error: any) {
            setSubmitError(error.message);
            setShowSubmitError(true);
            setSubmitting(false);
        }
    };
    // #endregion

    return (
        <VStack spacing={5} w="full">
            <Heading data-testid="title" size="md">
                Allow {source ?? 'app'} to access and control your{' '}
                {import.meta.env.VITE_APP_NAME} devices?
            </Heading>
            <Divider />

            <TableContainer>
                <Table size="sm">
                    <Tbody>
                        {email.length > 0 && (
                            <Tr>
                                <Th>Email</Th>
                                <Td>{email}</Td>
                            </Tr>
                        )}
                        {phone.length > 0 && (
                            <Tr>
                                <Th>Phone</Th>
                                <Td>{phone}</Td>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </TableContainer>

            <VStack w="full" mt={5}>
                <form className="fillMaxWidth">
                    <HStack spacing={5}>
                        <SubmitBtn
                            dataTestId="userDenyBtn"
                            isDisabled={submitting}
                            onClick={handleCancelBtn}
                            text="Deny"
                            loadingText="Deny"
                            buttonType={SubmitType.Cancel}
                        />

                        <SubmitBtn
                            dataTestId="userAllowBtn"
                            isLoading={submitting}
                            isDisabled={loading}
                            onClick={handleAllowBtn}
                            text="Allow"
                            loadingText="Allow"
                        />
                    </HStack>

                    <FormControl isInvalid={showSubmitError}>
                        <FormErrorMessage mt={5}>
                            <Alert
                                status="error"
                                borderRadius={10}
                                alignItems="flex-start"
                                textAlign="left"
                            >
                                <AlertIcon mt={-0.5} />
                                <Text mt={0.5}>{submitError}</Text>
                            </Alert>
                        </FormErrorMessage>
                    </FormControl>
                </form>

                <Button
                    data-testid="signOutBtn"
                    mt={5}
                    colorScheme="gray"
                    size="sm"
                    fontWeight="normal"
                    loadingText="Signing out..."
                    isDisabled={submitting}
                    onClick={handleSignOut}
                    borderColor="gray.200"
                    borderWidth={1}
                >
                    Not you? Sign out
                </Button>
            </VStack>
        </VStack>
    );
}
