import { Box, Button, Link, Spinner, Text } from '@chakra-ui/react';
import 'react-phone-input-2/lib/material.css';
import '@styles/login.css';
import React from 'react';

// create type of either cancel or submit typescript type
export enum SubmitType {
    Cancel = 'Cancel',
    Submit = 'Submit'
}

interface SubmitBtnProps {
    dataTestId?: string;
    isLoading?: boolean;
    text?: string;
    loadingText?: string;
    isDisabled?: boolean;
    width?: string;
    onClick?: (e: any) => void;
    buttonType?: string;
}
export function SubmitBtn({
    dataTestId = 'submitBtn',
    isLoading = false,
    text = 'Sign in',
    loadingText = 'Signing in...',
    isDisabled = false,
    width = '100%',
    onClick,
    buttonType = SubmitType.Submit
}: Readonly<SubmitBtnProps>) {
    return (
        <Button
            data-testid={dataTestId}
            onClick={onClick}
            type="submit"
            // colorScheme="submitBtn"
            w={width}
            isLoading={isLoading}
            loadingText={loadingText}
            spinner={<Spinner size="sm" />}
            isDisabled={isLoading || isDisabled}
            // transition effect for custom background colors: https://stackoverflow.com/questions/74395425/how-to-add-transition-duration-on-hover-of-a-button-on-chakra-ui
            // this is hover color
            bgGradient={
                buttonType == SubmitType.Submit
                    ? 'linear-gradient(185deg, #b9d9f7 -17.66%, #7b92e0 37.93%, #3d54b9 123.86%, #0024b9 123.87%)'
                    : // darker medium gray color
                      'linear-gradient(185deg, #d9d9d9 -17.66%, #a1a1a1 37.93%, #6b6b6b 123.86%, #3b3b3b 123.87%)'
            }
            overflow="hidden"
            _before={{
                position: 'absolute',
                content: `""`,
                width: '100%',
                height: '100%',
                // this is the default color
                bgGradient: `${
                    buttonType == SubmitType.Submit
                        ? 'linear-gradient(185deg,#cbe3ff -17.66%,#8598fd 37.93%,#3c5bff 123.86%,#0026ee 123.87%)'
                        : // medium gray color
                          'linear-gradient(185deg,#e2e6ff -17.66%,#b5b5b5 37.93%,#7f7f7f 123.86%,#4c4c4c 123.87%)'
                }`,
                transition: '0.5s'
            }}
            _hover={{
                _before: { opacity: 0 }
            }}
            _active={{
                // this is clicked color
                bgGradient: `${
                    buttonType == SubmitType.Submit
                        ? 'linear-gradient(185deg,#99b3e6 -17.66%,#5c6db3 37.93%,#253f99 123.86%,#00128c 123.87%)'
                        : // dark gray color
                          'linear-gradient(185deg,#b9b9b9 -17.66%,#8c8c8c 37.93%,#5f5f5f 123.86%,#2f2f2f 123.87%)'
                }`
            }}
            _loading={{
                // this ensures the text color is still white when loading
                _before: { opacity: 0 }
            }}
            textColor="white"
            borderRadius={500}
            // size="lg"
            border="1px"
            borderColor="#e2e6ff"
            shadow="0px 4px 10px 5px rgba(0, 0, 0, 0.15)"
            // fontWeight="normal"
            // this allows text to wrap
            // height="auto"
            // blockSize="auto"
            whiteSpace="normal"
            style={
                !isLoading
                    ? {
                          height: 'auto',
                          whiteSpace: 'normal',
                          blockSize: 'auto',
                          wordBreak: 'break-word'
                      }
                    : {}
            }
        >
            <Text padding={2.5} zIndex={1}>
                {text}
            </Text>
        </Button>
    );
}

interface UserLinkProps {
    text: string;
    enableLink: boolean;
    linkAction: () => void;
}
export function UserLink({
    text,
    enableLink,
    linkAction
}: Readonly<UserLinkProps>) {
    return (
        <Box textAlign="right">
            <Link
                onClick={enableLink ? linkAction : () => false}
                fontSize={15}
                textColor={enableLink ? 'black' : 'gray'}
                _hover={{
                    textDecoration: enableLink ? 'underline' : 'none',
                    cursor: enableLink ? 'pointer' : 'not-allowed'
                }}
            >
                {text}
            </Link>
        </Box>
    );
}
