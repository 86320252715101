import {
    PhoneAuthProvider,
    EmailAuthProvider,
    GoogleAuthProvider,
    signOut as fbSignOut
} from '@firebase/auth';
import { auth } from './firebaseConfig';
import { logger } from '../utility/logger';

const TAG = 'FirebaseUtils';

export async function signOut() {
    try {
        await fbSignOut(auth);
        logger.info(TAG, 'Signed out');
    } catch (e) {
        logger.error(TAG, 'Error signing out', e);
    }
}

export function getProviders() {
    const userInfo = auth.currentUser?.providerData;
    if (userInfo != null) {
        userInfo.forEach((provider: any) => {
            logger.info(TAG, `providerID: ${provider.providerId}`);
        });
    }
}

export function isVerifiedUser() {
    const user = auth.currentUser;
    if (user == null) {
        return false;
    }

    const userInfo = user.providerData;
    const phoneProvider = userInfo.find(
        (provider: any) => provider.providerId === PhoneAuthProvider.PROVIDER_ID
    );
    const emailProvider = userInfo.find(
        (provider: any) => provider.providerId === EmailAuthProvider.PROVIDER_ID
    );
    const googleProvider = userInfo.find(
        (provider: any) =>
            provider.providerId === GoogleAuthProvider.PROVIDER_ID
    );
    const appleProvider = userInfo.find(
        (provider: any) => provider.providerId === 'apple.com'
    );

    const hasEmail = user.email != null;
    if (!hasEmail) {
        // if no email, check if user has phoneProvider which means they are verified
        if (phoneProvider != null) {
            return true;
        }
        return false;
    }

    if (emailProvider != null) {
        // email/password provider should be verified if user has email & email was verified
        return user.emailVerified;
    } else {
        // phone & oauth providers are considered verified
        return (
            phoneProvider != null ||
            googleProvider != null ||
            appleProvider != null
        );
    }
}
