import React, { ComponentType, useEffect, useRef, useState } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import {
    setEmail,
    setLoggedIn,
    setPhone,
    setToken,
    setUserId
} from '../redux/sessionSlice';
import { AppDispatch, RootState } from '../redux/store';
// other
import { User, onAuthStateChanged } from '@firebase/auth';
import { auth, analytics } from '../shared/auth/firebaseConfig';
import { logger } from '../shared/utility/logger';
import { getProviders, isVerifiedUser } from '../shared/auth/firebaseUtils';
import { setUserId as firebaseSetUserId } from '@firebase/analytics';
import * as Sentry from '@sentry/react';

export default function withAuth(CustomComponent: ComponentType<any>) {
    const TAG = 'Middleware';

    return function AuthComponent() {
        const dispatch = useDispatch<AppDispatch>();
        const currentQueryParams = window.location.search;

        const session = useSelector((state: RootState) => state.session);

        useEffect(() => {
            init();
        }, []);

        async function init() {
            await auth.authStateReady();

            checkCurrentUser();

            onAuthStateChanged(auth, (user) => {
                checkCurrentUser();
            });

            console.log('session', session);
        }

        async function checkCurrentUser() {
            const user = auth.currentUser;
            if (user) {
                logger.info(TAG, `UID: ${user.uid}`);
                logger.info(TAG, `Phone: ${user.phoneNumber}`);
                logger.info(TAG, `Email: ${user.email}`);
                logger.info(TAG, `Email verified: ${user.emailVerified}`);
                getProviders();

                if (isVerifiedUser()) {
                    if (window.location.pathname.startsWith('/login')) {
                        // get query params redirect_uri and state
                        const urlParams = new URLSearchParams(
                            window.location.search
                        );
                        const redirectUri = urlParams.get('redirect_uri');

                        logger.info(TAG, 'User is verified');

                        // this makes it so the user component isn't show if logging in for first time unless a redirect_uri is present
                        if (session.loggedIn === false || redirectUri) {
                            dispatch(setLoggedIn(true));
                        }

                        setUserData(user);

                        // only proceed to next page if this is not an enable alexa / google home request
                        if (!redirectUri) {
                            // redirect to /devices page with query params
                            // 	window.location.href = `/devices${currentQueryParams}`;
                        }
                    }
                } else {
                    logger.info(TAG, 'User is not verified');
                    initSession();
                }
            } else {
                logger.info(TAG, 'No user');
                initSession();
            }
        }

        function initSession() {
            dispatch(setLoggedIn(false));
            dispatch(setToken(''));

            redirectToLogin();
        }

        function setUserData(user: User) {
            // analytics set user id
            firebaseSetUserId(analytics, user.uid);
            // Sentry set user
            Sentry.setUser({ id: user.uid });
            // Set user data
            dispatch(setUserId(user.uid));
            dispatch(setEmail(user.email ?? ''));
            dispatch(setPhone(user.phoneNumber ?? ''));
        }

        function redirectToLogin() {
            if (window.location.pathname !== '/login') {
                // redirect to login page with query params
                window.location.href = `/login${currentQueryParams}`;
            }
        }

        return <CustomComponent />;
    };
}
