import { Container, Center, Stack } from '@chakra-ui/react';
import React from 'react';

export default function NotFound() {
    return (
        <Container padding={8} maxW="container.md">
            <Stack align="center">
                <h1>Page Not Found</h1>
                <p>Uh Oh. The requested page does not exist.</p>
            </Stack>
        </Container>
    );
}
