import { useLocation } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
// pages
import Navbar from '../components/layout/navbar';
import withAuth from '../components/middleware.tsx'; // Import the middleware
import NotFound from '../pages/not-found';
import Verification from '../pages/verification.tsx';
import Home from '../pages/home.tsx';
import Login from '../pages/login.tsx';
const AuthLogin = withAuth(Login);
// import Devices from '../../unused/devices.tsx';
// const AuthDevices = withAuth(Devices);
// import Subscription from '../../unused/subscription.tsx';
// const AuthSubscription = withAuth(Subscription);
// import CancelSubscription from '../../unused/cancel-subscription.tsx';
// const AuthCancelSubscription = withAuth(CancelSubscription);
// images
import { putLogs } from '../shared/utility/logger';
import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';

export const App = () => {
    const location = useLocation();
    const currentQueryParams = window.location.search;
    const urlParams = new URLSearchParams(currentQueryParams);
    const redirect_uri = urlParams.get('redirect_uri');
    const response_type = urlParams.get('response_type');
    const hideNavbar =
        location.pathname == '/verification' ||
        ((location.pathname === '/login' || location.pathname === '/user') &&
            redirect_uri != null &&
            response_type != null); // Specify the pages where the navbar should be shown
    const headerRef = useRef<HTMLElement>(null);
    const [headerHeight, setHeaderHeight] = useState(0);

    useEffect(() => {
        const navbarHeight =
            (headerRef.current as HTMLElement)?.offsetHeight ?? 0;
        if (navbarHeight > 0) {
            setHeaderHeight(navbarHeight + 5); // 5px padding
        } else {
            setHeaderHeight(12);
        }

        // run code when user tries to leave the page
        // https://stackoverflow.com/questions/69741534/is-there-a-way-to-get-a-useeffect-cleanup-to-run-when-a-user-closes-the-webpage
        const onBeforeUnload = async (e: any) => {
            console.debug('onBeforeUnload');
            // NOTE: note entirely sure if this will be able to execute fully when the user closes the page
            putLogs();
        };
        window.addEventListener('beforeunload', onBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload);
        };
    }, []);

    return (
        <Box w="100vw">
            <Flex
                ref={headerRef}
                as="header"
                position="fixed"
                w="full"
                zIndex={1}
            >
                {!hideNavbar && <Navbar />}
            </Flex>

            <Box position="absolute" w="full" mt={`${headerHeight}px`}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route
                        path="/login"
                        element={
                            // center the login form while accounting for the navbar height
                            <Box
                                position="absolute"
                                w="full"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                minHeight={`calc(100vh - ${headerHeight}px)`}
                                pb={10}
                            >
                                <AuthLogin />
                            </Box>
                        }
                    />
                    <Route path="/verification" element={<Verification />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Box>
        </Box>
    );
};
