import { logger } from './logger';
import * as Sentry from '@sentry/react';

const TAG = 'Utility';

// https://stackoverflow.com/a/65156250/16762230
export const stringifyWithBigInts = (obj: any) => {
    return JSON.stringify(obj, (_, value) =>
        typeof value === 'bigint' ? value.toString() : value
    );
};

// #region http
export const VA_ENDPOINT =
    import.meta.env.VITE_ENV !== 'prod'
        ? 'https://dev-va.caraplug.com/v1'
        : 'https://va.caraplug.com/v1';

export enum HttpMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE'
}

interface HttpRequestOptions {
    method?: HttpMethod;
    headers?: Headers;
    body?: string;
}

export const httpRequest = (
    url: string,
    options: HttpRequestOptions,
    abortSignal?: AbortSignal | null
) => {
    return new Promise((resolve, reject) => {
        const method = options?.method ?? HttpMethod.GET;

        const input: RequestInit = {};
        if (options != null && Object.keys(options).length > 0) {
            Object.assign(input, options);
        }
        if (abortSignal != null) {
            input.signal = abortSignal;
        }

        const urlToPrint = url
            // strip key query param
            .replace(/key=[^&]+/, 'key=****');

        fetch(url, input)
            .then(async (res) => {
                logger.info(
                    TAG,
                    `[${method} ${urlToPrint}] - Status code: ${res.status}`
                );

                const bodyIsEmpty = res.status == 204 || res.body == null;
                const body = bodyIsEmpty ? null : await res.text();

                // ok means status code is 200-299
                if (res.ok) {
                    resolve({
                        statusCode: res.status,
                        headers: res.headers,
                        // return null if no body
                        body
                    });
                } else {
                    logger.info(TAG, body);
                    logger.info(
                        TAG,
                        `[${method} ${urlToPrint}] - FAIL request with status code: ${res.status}`
                    );

                    let msg = `An error occurred with status code: ${res.status}`;
                    if (body != null) {
                        try {
                            const json = JSON.parse(body);
                            if (json.message != null) {
                                msg = json.message;
                            } else if (json.validationErrors != null) {
                                msg = 'Validation error';
                            }
                        } catch (err) {}
                    }

                    reject(
                        new Error(
                            msg
                            // JSON.stringify({
                            //     statusCode: res.status,
                            //     headers: res.headers,
                            //     // return null if no body
                            //     body
                            // })
                        )
                    );
                }
            })
            .catch((err) => {
                const json = errorToJson(err);
                logger.error(TAG, json);
                logger.error(TAG, `[${method} ${urlToPrint}] - FAIL request`);

                Sentry.captureException(err);

                reject(err);
            });
    });
};
// #endregion

export function errorToJson(err) {
    let rawError = err;
    // if rawError is in a string json, try to convert it to json
    if (typeof rawError == 'string') {
        let temp;
        try {
            temp = JSON.parse(rawError);
        } catch (e) {
            // if it failed, simply store the original string value
            temp = rawError;
        }
        rawError = temp;
    }
    const json = {
        errorType: err.code, // only shows up for node's built in error messages
        errorMessage: err.message,
        trace: err.stack,
        rawError
    };
    return json;
}
