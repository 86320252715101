import {
    FormControl,
    FormErrorMessage,
    Text,
    HStack,
    Alert,
    AlertIcon,
    VStack
} from '@chakra-ui/react';
import { PhoneIcon } from '@chakra-ui/icons';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { useEffect, useState } from 'react';
import '@styles/login.css';
import { SubmitBtn } from './Components';
import { phone as phoneValidator } from 'phone';
import { logger } from '../../shared/utility/logger';
import { getMessageFromCode } from '../../shared/auth/firebaseErrorMessages';
import { auth } from '../../shared/auth/firebaseConfig';
import { signInWithPhoneNumber } from '@firebase/auth';
import React from 'react';

const TAG = 'Login';

interface LoginWithPhoneProps {
    phoneShowing: boolean;
    handlePhoneSignInBtn: () => void;
    resetCaptcha: () => void;
    currentPhone: React.MutableRefObject<string>;
}
export default function LoginWithPhone({
    phoneShowing,
    handlePhoneSignInBtn,
    resetCaptcha,
    currentPhone
}: Readonly<LoginWithPhoneProps>) {
    // #region business-logic
    const [phone, setPhone] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const [showPhoneError, setShowPhoneError] = useState(false);
    const [submitError, setSubmitError] = useState('');
    const [showSubmitError, setShowSubmitError] = useState(false);

    useEffect(() => {
        if (phoneShowing) {
            // focus on phone input
            document.getElementsByName('phone')[0]?.focus();
            // reset errors
            setShowPhoneError(false);
            setSubmitError('');
            setShowSubmitError(false);
            // reset form
            // TODO: reset phone without clearing the country code
            setPhone('1');
        }
    }, [phoneShowing]);

    const checkValidPhone = (value: any, country: any) => {
        if (value.match(/12345/)) {
            return 'Invalid phone';
        } else if (showPhoneError) {
            return 'Invalid phone';
        } else {
            return true;
        }
    };

    const validPhone = (phone: string) => {
        return phoneValidator(phone).isValid;
    };

    const handlePhoneLogin = async (e: any) => {
        e.preventDefault();

        if (submitting) {
            return;
        }

        setShowSubmitError(false);

        const phoneError = !validPhone(phone);
        setShowPhoneError(phoneError);

        if (phoneError) {
            document.getElementsByName('phone')[0]?.focus();
            return;
        }

        setSubmitting(true);
        // set timeout after 5s to setIsPhoneCodeRequestLoading(false) in case user closes out of captcha
        setTimeout(() => {
            setSubmitting(false);
        }, 5000);
        try {
            const finalPhone = `+${phone}`;
            const appVerifier = window.recaptchaVerifier;
            const confirmationResult = await signInWithPhoneNumber(
                auth,
                finalPhone,
                appVerifier
            );
            window.confirmationResult = confirmationResult;

            // save phone to be used for phone code verification
            currentPhone.current = finalPhone;

            // move to phone code verification
            handlePhoneSignInBtn();

            setSubmitting(false);
        } catch (error: any) {
            logger.info(TAG, error);
            resetCaptcha();
            const message = getMessageFromCode(error.code);
            setSubmitError(message);
            setShowSubmitError(true);

            setSubmitting(false);

            // set focus on phone input (NOTE: need a slight delay for focus to work & must be after submitting is false)
            setTimeout(() => {
                document.getElementsByName('phone')[0]?.focus();
            }, 100);
        }
    };
    // #endregion

    return (
        <form onSubmit={handlePhoneLogin}>
            <VStack align="start" spacing={5}>
                <HStack spacing="12px" align={'center'} mb={0}>
                    <PhoneIcon fontSize={25} />
                    <Text>Phone</Text>
                </HStack>

                <VStack w="full">
                    <PhoneInput
                        enableSearch={true}
                        value={phone}
                        onChange={(phone) => setPhone(phone)}
                        enableAreaCodeStretch={true}
                        inputStyle={{ width: '100%' }} // Added inline style to make the input fill the parent width
                        isValid={(value, country) =>
                            !showPhoneError && checkValidPhone(value, country)
                        }
                        inputProps={{
                            name: 'phone',
                            required: true,
                            autoFocus: true
                        }}
                        countryCodeEditable={false}
                        onEnterKeyPress={handlePhoneLogin}
                        disabled={submitting}
                    />

                    <FormControl isInvalid={showPhoneError} mt={-2}>
                        <FormErrorMessage>
                            <Text>Please enter a valid phone</Text>
                        </FormErrorMessage>
                    </FormControl>
                </VStack>

                <SubmitBtn isLoading={submitting} />

                <FormControl
                    isInvalid={showSubmitError}
                    isDisabled={submitting}
                    mt={-2}
                >
                    <FormErrorMessage>
                        <Alert
                            status="error"
                            borderRadius={10}
                            alignItems="flex-start"
                            textAlign="left"
                        >
                            <AlertIcon />
                            <Text mt={0.5}>{submitError}</Text>
                        </Alert>
                    </FormErrorMessage>
                </FormControl>
            </VStack>
        </form>
    );
}
