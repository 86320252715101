import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
    HStack,
    useToast,
    Alert,
    AlertIcon,
    VStack,
    Spacer
} from '@chakra-ui/react';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { EmailIcon } from '@chakra-ui/icons';
import 'react-phone-input-2/lib/material.css';
import { useEffect, useState } from 'react';
import '@styles/login.css';
import { UserLink, SubmitBtn } from './Components';
import emailValidator from 'email-validator';

import { logger } from '../../shared/utility/logger';
import { getMessageFromCode } from '../../shared/auth/firebaseErrorMessages';
import { auth } from '@root/src/shared/auth/firebaseConfig';
import {
    sendPasswordResetEmail,
    signInWithEmailAndPassword
} from '@firebase/auth';
import { signOut } from '@root/src/shared/auth/firebaseUtils';
import React from 'react';
const TAG = 'Login';

interface LoginWithEmailProps {
    emailShowing: boolean;
    // authSuccess: () => Promise<void>;
}
export default function LoginWithEmail({
    emailShowing
}: // authSuccess
Readonly<LoginWithEmailProps>) {
    // #region business-logic
    const toast = useToast();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const {
        isOpen: isModalOpen,
        onOpen: openModal,
        onClose: closeModal
    } = useDisclosure();
    const [emailError, setEmailError] = useState('');
    const [showEmailError, setShowEmailError] = useState(false);
    const [showPasswordError, setShowPasswordError] = useState(false);
    const [submitError, setSubmitError] = useState('');
    const [showSubmitError, setShowSubmitError] = useState(false);
    const [allowSendPasswordReset, setAllowSendPasswordReset] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (emailShowing) {
            // focus on email input
            document.getElementById('email')?.focus();
            // reset errors
            setEmailError('');
            setShowEmailError(false);
            setShowPasswordError(false);
            setSubmitError('');
            setShowSubmitError(false);
            // reset form
            setEmail('');
            setPassword('');
            setShowPassword(false);
        }
    }, [emailShowing]);

    const handleEmailLogin = async (e: any) => {
        e.preventDefault();

        if (submitting) {
            return;
        }

        setShowSubmitError(false);

        const emailError = !validEmail(email);
        if (emailError) {
            setEmailError('Please enter a valid email');
            document.getElementsByName('email')[0]?.focus();
        }
        setShowEmailError(emailError);

        const passwordError = !validPassword(password);
        if (passwordError) {
            document.getElementsByName('password')[0]?.focus();
        }
        setShowPasswordError(passwordError);

        if (emailError || passwordError) {
            return;
        }

        setSubmitting(true);
        try {
            const result = await signInWithEmailAndPassword(
                auth,
                email,
                password
            );
            const { user } = result;
            logger.info(TAG, 'user:', user);
            // check if if email is verified
            if (!user.emailVerified) {
                // sign out
                signOut();
                throw new Error('Email is not verified');
            }

            // try {
            //     await authSuccess();
            // } catch (err: any) {
            //     logger.info(TAG, err);
            //     setSubmitError(err.message);
            //     setShowSubmitError(true);
            // }

            setSubmitting(false);
        } catch (error: any) {
            logger.info(TAG, error);
            const message = getMessageFromCode(error.code);
            setSubmitError(message);
            setShowSubmitError(true);

            setSubmitting(false);
        }
    };

    const handleForgotPassword = async () => {
        if (!allowSendPasswordReset) {
            return;
        }

        setShowPasswordError(false);
        setShowSubmitError(false);

        const emailError = !validEmail(email);
        if (emailError) {
            setEmailError('Please enter a valid email');
        }
        setShowEmailError(emailError);

        if (emailError) {
            return;
        }

        setAllowSendPasswordReset(false);
        try {
            await sendPasswordResetEmail(auth, email);
            openModal();

            // allow sending password reset after 10s
            setTimeout(() => {
                setAllowSendPasswordReset(true);
            }, 10000);
        } catch (error: any) {
            logger.info(TAG, error);
            const message = getMessageFromCode(error.code);
            toast({
                description: message,
                status: 'error',
                variant: 'top-accent',
                isClosable: true
            });
            setAllowSendPasswordReset(true);
        }
    };

    const validEmail = (email: string) => {
        return emailValidator.validate(email);
    };

    const validPassword = (password: string) => {
        return password.length >= 6;
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    // #endregion

    return (
        <Box width="100%">
            <form onSubmit={handleEmailLogin}>
                <VStack align="start" spacing={5}>
                    <HStack spacing="12px" align={'center'}>
                        <EmailIcon fontSize={25} />
                        <Text>Email</Text>
                    </HStack>

                    <FormControl
                        id="email"
                        isRequired
                        isInvalid={showEmailError}
                        isDisabled={submitting}
                    >
                        <Input
                            data-testid="emailInput"
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="email"
                            variant="flushed"
                        />
                        <FormErrorMessage>{emailError}</FormErrorMessage>
                    </FormControl>

                    <FormControl
                        id="password"
                        isRequired
                        isInvalid={showPasswordError}
                        isDisabled={submitting}
                    >
                        <InputGroup>
                            <Input
                                data-testid="passwordInput"
                                pr="3.5rem"
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                autoComplete="current-password"
                                variant="flushed"
                            />
                            <InputRightElement width="3.5rem">
                                <Button
                                    h="1.75rem"
                                    size="sm"
                                    onClick={handleTogglePasswordVisibility}
                                >
                                    {showPassword ? <HiEyeOff /> : <HiEye />}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>
                            Password is too short
                        </FormErrorMessage>
                    </FormControl>

                    <SubmitBtn
                        dataTestId="emailSignInBtn"
                        isLoading={submitting}
                    />

                    <Flex w="full" mt={-2}>
                        <Spacer />
                        <UserLink
                            text="Forgot password?"
                            enableLink={allowSendPasswordReset && !submitting}
                            linkAction={handleForgotPassword}
                        />
                    </Flex>

                    <FormControl isInvalid={showSubmitError} mt={-4}>
                        <FormErrorMessage>
                            <Alert
                                status="error"
                                borderRadius={10}
                                alignItems="flex-start"
                                textAlign="left"
                            >
                                <AlertIcon mt={-0.5} />
                                <Text mt={0.5}>{submitError}</Text>
                            </Alert>
                        </FormErrorMessage>
                    </FormControl>
                </VStack>
            </form>

            <Modal
                closeOnOverlayClick={false}
                isCentered
                onClose={closeModal}
                isOpen={isModalOpen}
                motionPreset="slideInBottom"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Password reset email sent!</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex direction="column" alignItems="center">
                            <Icon
                                as={EmailIcon}
                                boxSize={20}
                                color="blue.500"
                            />
                            <Text mt={2}>
                                An email was sent to your inbox. Follow the
                                instructions to reset your password. Then come
                                back here to log in.
                            </Text>
                        </Flex>
                    </ModalBody>
                    <ModalFooter justifyContent="center">
                        <Button colorScheme="blue" onClick={closeModal}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
}
