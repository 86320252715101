import ReactDOM from 'react-dom/client';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './redux/store';
// analytics
import { Analytics } from '@vercel/analytics/react';
// import { SpeedInsights } from '@vercel/speed-insights/next';
// app
import { App } from './app/app';
import './shared/utility/sentry';
import { initLogger } from './shared/utility/logger';
import '@styles/main.css';
import React from 'react';

initLogger();

const theme = extendTheme({
    colors: {
        brand: {
            primary: 'black',
            lightBlue: '#0085FF'
        },
        submitBtn: {
            500: 'rgba(230, 235, 240, 1)',
            600: 'rgba(230, 235, 240, 1)',
            700: '#1c0081'
        }
    },
    components: {
        Input: {
            defaultProps: {
                focusBorderColor: 'brand.lightBlue'
            }
        }
    },
    styles: {
        global: () => ({
            body: {
                bg: ''
            }
        })
    }
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <>
        <Analytics />

        <ChakraProvider theme={theme}>
            <BrowserRouter>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <App />
                    </PersistGate>
                </Provider>
            </BrowserRouter>
        </ChakraProvider>
    </>
);
