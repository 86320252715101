import jwt_decode from 'jwt-decode';
import { logger } from '../utility/logger';
import { isVerifiedUser, signOut } from './firebaseUtils';
import { auth } from './firebaseConfig';
import store from '../../redux/store';
import { setToken } from '@root/src/redux/sessionSlice';
import { AuthErrorCodes } from '@firebase/auth';

const TAG = 'TOKEN';

export function isTokenExpired() {
    const token = store.getState().session.token;

    try {
        const decoded: any = jwt_decode(token);
        const exp = new Date(0); // The 0 there is the key, which sets the date to the epoch
        exp.setUTCSeconds(decoded.exp);
        const now = new Date(new Date().toUTCString());
        const diff = exp.getTime() - now.getTime();
        const diffMins = diff / (60 * 1000);
        console.debug(`token diffMins: ${diffMins}`);

        // refresh token if it's less than 5 minutes from expiring
        return diffMins < 5;
    } catch (e) {}

    return true;
}

export async function refreshToken() {
    if (!isTokenExpired()) {
        // token isn't expired, exit
        return;
    }
    const user = auth.currentUser;
    if (user == null) {
        logger.info(TAG, 'User is null');
        throw new Error('User is null');
    }

    try {
        const token = await user.getIdToken(true);
        store.dispatch(setToken(token));
        logger.info(TAG, `token: ${token}`);
    } catch (e: any) {
        logger.info(TAG, e);
        const errorCode = e.code;
        logger.info(TAG, `errorCode: ${errorCode}`);
        switch (errorCode) {
            case AuthErrorCodes.INVALID_AUTH:
            case AuthErrorCodes.INVALID_LOGIN_CREDENTIALS:
            case AuthErrorCodes.USER_MISMATCH:
            case AuthErrorCodes.USER_DELETED:
            case AuthErrorCodes.USER_DISABLED:
                signOut();
                break;
            default:
                break;
        }
        throw e;
    }
}

export async function waitForToken() {
    const user = auth.currentUser;
    if (user == null) {
        logger.info(TAG, 'User is null');
        throw new Error('User is null');
    }
    if (!isVerifiedUser()) {
        signOut();
        const error = 'User is not verified';
        logger.info(TAG, error);
        throw new Error(error);
    }

    while (!navigator.onLine) {
        logger.info(TAG, 'Waiting for an internet connection...');
        await new Promise((resolve) => setTimeout(resolve, 2000));
    }

    let stayInLoop = true;
    while (stayInLoop && isTokenExpired()) {
        logger.info(TAG, 'Waiting for token to be refreshed...');
        if (navigator.onLine) {
            await refreshToken();
            if (!isTokenExpired()) {
                logger.info(TAG, 'Token has been refreshed');
                stayInLoop = false;
            }
        }
        if (stayInLoop) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
        }
    }

    try {
        // when user changes account sign in elsewhere, the reload function will automatically sign out user, and ContentViewModel Firebase auth handler will automatically move user back to login screen
        await user.reload();
    } catch (e: any) {
        logger.info(TAG, 'user reload error: ', e);
        // check error code before signing out
        const errorCode = e.code;
        logger.info(TAG, `errorCode: ${errorCode}`);
        switch (errorCode) {
            case AuthErrorCodes.INVALID_AUTH:
            case AuthErrorCodes.INVALID_LOGIN_CREDENTIALS:
            case AuthErrorCodes.USER_MISMATCH:
            case AuthErrorCodes.USER_DELETED:
            case AuthErrorCodes.USER_DISABLED:
                signOut();
                break;
            default:
                break;
        }
        throw e;
    }
}
