import pino, { Logger } from 'pino';
import {
    CloudWatchLogs,
    CloudWatchLogsClient,
    CreateLogStreamCommand,
    InputLogEvent,
    PutLogEventsCommand,
    ResourceAlreadyExistsException,
    ResourceNotFoundException
} from '@aws-sdk/client-cloudwatch-logs';
import { v4 as uuidv4 } from 'uuid';
import { errorToJson, stringifyWithBigInts } from './utility';
import {
    CognitoIdentityClient,
    Credentials,
    GetCredentialsForIdentityCommand,
    GetIdCommand
} from '@aws-sdk/client-cognito-identity';

const AWS_REGION = import.meta.env.VITE_AWS_REGION!;
const COGNITO_IDENTITY_POOL_ID = import.meta.env.VITE_COGNITO_IDENTITY_POOL_ID!;

let creds: Credentials | undefined;
const cognitoClient = new CognitoIdentityClient({
    region: AWS_REGION
});
let cloudwatchLogs: CloudWatchLogs | undefined;

async function initClients(): Promise<void> {
    let needsRefresh = creds == null || cloudwatchLogs == null;

    if (creds && creds.Expiration) {
        const expirationTime = Math.floor(creds.Expiration.getTime() / 1000);
        const currentTime = Math.floor(Date.now() / 1000);
        const diffSeconds = expirationTime - currentTime;
        const diffMinutes = diffSeconds / 60;
        console.debug(`Credentials expire in ${diffMinutes} minutes`);

        if (diffMinutes <= 1) {
            console.debug('Credentials are expired, refreshing');
            needsRefresh = true;
        }
    }

    if (needsRefresh) {
        try {
            // Get the identity ID for the unauthenticated user
            const identityIdResponse = await cognitoClient.send(
                new GetIdCommand({
                    IdentityPoolId: COGNITO_IDENTITY_POOL_ID
                })
            );

            // Fetch credentials for the identity
            const credentialsResponse = await cognitoClient.send(
                new GetCredentialsForIdentityCommand({
                    IdentityId: identityIdResponse.IdentityId
                })
            );

            creds = credentialsResponse.Credentials;

            if (creds) {
                // Initialize CloudWatch and S3 clients with the new credentials
                cloudwatchLogs = new CloudWatchLogs({
                    region: AWS_REGION,
                    credentials: {
                        accessKeyId: creds.AccessKeyId!,
                        secretAccessKey: creds.SecretKey!,
                        sessionToken: creds.SessionToken
                    }
                });
            } else {
                throw new Error('Credentials are null');
            }
        } catch (error) {
            console.error('Error initializing clients: ', error);
            throw error;
        }
    }

    // Return a resolved Promise to replicate Task<Unit>() behavior
    return Promise.resolve();
}

async function getCloudwatchLogsClient(): Promise<CloudWatchLogs> {
    await initClients();
    if (cloudwatchLogs == null) {
        throw new Error('CloudWatchLogs client is null');
    }
    return cloudwatchLogs;
}

const application = import.meta.env.VITE_APPLICATION;
const environment = import.meta.env.VITE_ENV;
const project = 'login-and-device-management';
const logGroupName = `${application}-${environment}-${project}`;
console.debug('Creating Pino logger to send logs to log group:', logGroupName); // not actually using Pino transport, but rather calling cloudwatch send commands from send callback by Pino

let currentLogStream: string = getLastLogStreamName();

function getLastLogStreamName() {
    const logStreamName = sessionStorage.getItem('logStreamName');

    const logStreamCreatedAt = sessionStorage.getItem('logStreamCreatedAt');
    if (logStreamName == null || logStreamCreatedAt == null) {
        sessionStorage.removeItem('logStreamName');
        sessionStorage.removeItem('logStreamCreatedAt');
        return createNewLogStreamName();
    }
    // if over 1 minute old, set to null
    const createdAt = new Date(logStreamCreatedAt);
    const now = new Date();
    const diff = now.getTime() - createdAt.getTime();
    if (diff > 60 * 1000) {
        sessionStorage.removeItem('logStreamName');
        sessionStorage.removeItem('logStreamCreatedAt');
        return createNewLogStreamName();
    }
    return logStreamName;
}

function createNewLogStreamName() {
    const dateTime = new Date().toISOString();
    // format to YYYY/MM/DD
    const date = dateTime.split('T')[0].replace(/-/g, '/');
    const randomId = uuidv4();
    const logStreamName = `${date} ${randomId}`;

    sessionStorage.setItem('logStreamName', logStreamName);
    sessionStorage.setItem('logStreamCreatedAt', new Date().toISOString());

    return logStreamName;
}

async function createLogStream(logStreamName: string) {
    const input = {
        logGroupName,
        logStreamName
    };
    const command = new CreateLogStreamCommand(input);
    try {
        await (await getCloudwatchLogsClient()).send(command);
    } catch (err) {
        // possible that log stream got created just before this call got
        if (!(err instanceof ResourceAlreadyExistsException)) {
            throw err;
        }
    }
}

let logEvents: InputLogEvent[] = [];
export async function putLogs() {
    if (logEvents.length === 0) return;

    console.debug('Sending logs...');

    const copyLogEvents = [...logEvents];
    // clear logEvents
    logEvents = [];

    const input = {
        logGroupName,
        logStreamName: currentLogStream,
        logEvents: copyLogEvents
    };
    const command = new PutLogEventsCommand(input);
    try {
        await (await getCloudwatchLogsClient()).send(command);
    } catch (err) {
        if (err instanceof ResourceNotFoundException) {
            await createLogStream(currentLogStream);
        }

        await (await getCloudwatchLogsClient()).send(command);
    }
    // NOTE: decided not to add copyLogEvents back to logEvents if there is an error
}

let pinoLogger: Logger;

const formatMsg = (tag: string, message: any[]) => {
    const result: string[] = [`[${tag}]`];
    for (const item of message) {
        if (typeof item === 'object') {
            try {
                // replace first instance of [ and last instance of ]
                let output = stringifyWithBigInts(message)
                    .substring(1)
                    .slice(0, -1);
                // if output is surrounded by quotes, remove them
                if (output.startsWith('"') && output.endsWith('"')) {
                    output = output.substring(1).slice(0, -1);
                }
                result.push(output);
            } catch (err) {
                result.push(item);
            }
        } else {
            result.push(item);
        }
    }
    return result.join(' ');
};

const logger = {
    debug(tag: string, ...message: any[]) {
        if (pinoLogger != null) {
            pinoLogger.debug(formatMsg(tag, message));
        } else {
            console.debug(tag, message.join(' '));
        }
    },
    info(tag: string, ...message: any[]) {
        if (pinoLogger != null) {
            pinoLogger.info(formatMsg(tag, message));
        } else {
            console.info(tag, message.join(' '));
        }
    },
    warn(tag: string, ...message: any[]) {
        if (pinoLogger != null) {
            pinoLogger.warn(formatMsg(tag, message));
        } else {
            console.warn(tag, message.join(' '));
        }
    },
    error(tag: string, ...message: any[]) {
        if (pinoLogger != null) {
            pinoLogger.error(formatMsg(tag, message));
        } else {
            console.error(tag, message.join(' '));
        }
    },
    logError(tag: string, err: Error) {
        if (pinoLogger != null) {
            const message = errorToJson(err);
            pinoLogger.error(formatMsg(tag, [message]));
        } else {
            console.error(tag, err);
        }
    }
};

const initLogger = () => {
    pinoLogger = pino({
        name: 'logger',
        level: 'debug',
        formatters: {
            level: (label) => {
                return { level: label.toUpperCase() };
            }
        },
        timestamp: pino.stdTimeFunctions.isoTime,
        browser: {
            transmit: {
                send: function (level, logEvent) {
                    const timestamp = new Date(logEvent.ts).getTime();

                    // should only be 1 element in messages array since it got joined from formatMsg
                    const message = logEvent.messages[0];

                    const tag = message.split(']')[0].substring(1);
                    // messageTxt is rest of message after tag - leading space
                    // NOTE: won't throw error if substring is longer than message
                    const messageText = message.substring(tag.length + 3); // length is tag + ']' + ' '

                    logEvents.push({
                        timestamp,
                        message: JSON.stringify({
                            timestamp,
                            level: level.toUpperCase(),
                            tag,
                            message: messageText
                        })
                    });
                }
            }
        }
    });

    setInterval(() => {
        currentLogStream = createNewLogStreamName();
    }, 60 * 1000);

    setInterval(() => {
        putLogs().catch((err) => {
            console.debug('putLogs error:', err);
        });
    }, 2 * 1000);

    // TODO: somehow inject logger into all functions that currently use console.log
    // NOTE: currently disabled override console logs to be sent through pino logger since app check throws lots of errors
    const defaultTag = 'console';
    console.log = (...message) => {
        logger.info(defaultTag, ...message);
    };
    console.info = (...message) => {
        logger.info(defaultTag, ...message);
    };
    console.warn = (...message) => {
        logger.warn(defaultTag, ...message);
    };
    console.error = (...message) => {
        logger.error(defaultTag, ...message);
    };
};

export { initLogger, logger };
